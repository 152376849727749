.console-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #000;
    color: #00ff00;
    font-family: "Courier New", Courier, monospace;
    overflow: hidden;
}
  
.console-output {
    flex: 1; /* Takes up all the available space except for the input field */
    padding: 10px;
    overflow-y: auto; /* Allows scrolling when output exceeds the screen height */
    white-space: pre-wrap; /* Preserves line breaks */
}
  
.console-input {
    border-top: 2px solid #00ff00;
    background-color: #000;
    padding: 10px;
}
  
.console-input input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: "Courier New", Courier, monospace;
    color: #00ff00;
    background-color: #000;
    border: 1px solid #00ff00;
    outline: none;
}
  
.console-input input::placeholder {
    color: #008000;
}